import './Navbar.css';


function Navbar() {
  return (
    <header> 
        <nav className="navbar">
            <div className="navbar-container">
                <a className="navbar-logo" href='#'>RM</a>
                <div className='navbar-menu-collapse'>
                    <ul class="navbar-nav">
                        <li class="nav-item"><a class="nav-link active" aria-current="page" href="#">Home</a></li>
                        <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                        <li class="nav-item"><a class="nav-link" href="#skills">Skills</a></li>
                        <li class="nav-item"><a class="nav-link" href="#projects">Projects</a></li>
                        <li class="nav-item"><a class="nav-link" href="#awards">Awards and Certifications</a></li></ul>
                </div>
            </div>
            
        </nav>
        
    </header>
  );
}

export default Navbar;
