const skillsData = [
    {
      category: 'Front-End',
      skills: [
        { name: 'HTML', alt: 'HTML',img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-original.svg'},
        { name: 'CSS' , alt: 'CSS', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-original.svg'},
        { name: 'JavaScript',alt: 'JavaScript', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg' },
        { name: 'React' , alt: 'React', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg'},
        { name: 'Angular',  alt: 'Angular', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/angular/angular-original.svg ' },
        { name: 'Bootstrap', alt: 'Bootstrap', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/bootstrap/bootstrap-original.svg' }
      ]
    },
    {
      category: 'Back-End',
      skills: [
        { name: 'Node.js' , alt: 'Node', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-original-wordmark.svg'},
        { name: 'Django' , alt: 'Django', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/django/django-plain.svg'},
        { name: 'Flask' , alt: 'Flask', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/flask/flask-original.svg'},
        { name: 'SpringBoot' , alt: 'SpringBoot', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/spring/spring-original.svg'}
      ]
    },

    {
      category: 'Databases',
      skills: [
        { name: 'MySQL' , alt: 'MySQL', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original-wordmark.svg'},
        { name: 'PostgreSQL' , alt: 'PostgreSQL', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/postgresql/postgresql-original.svg'},
        { name: 'Redis' , alt: 'Redis', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/redis/redis-original.svg'}
      ]
    },
    {
      category: 'DevOps / Infrastructure',
      skills: [
        { name: 'AWS' , alt: 'AWS', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg'},
        { name: 'Google' , alt: 'Google', img:'https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/googlecloud/googlecloud-original.svg'}
      ]
    },
    
  ];
  
  export default skillsData;
  