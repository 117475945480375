import React from 'react';
import SkillCard from '../SkillCard/SkillCard';
import './SkillSection.css'; // Importar CSS para estilização

const SkillSection = ({ skillsData }) => {
  console.log('Skills Data in SkillSection:', skillsData); // Adicione este log para verificar os dados

  if (!skillsData) {
    return <div>Loading...</div>; // Ou outra mensagem para o caso de dados não estarem disponíveis
  }

  return (
    <div className="skill-section">
      {skillsData.map((categoryData, index) => (
        <div key={index} className="skill-category">
          <h2>{categoryData.category}</h2>
          <div className="skill-row">
            {categoryData.skills.map((skill, skillIndex) => (
              <SkillCard skill={skill} key={skillIndex} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkillSection;
