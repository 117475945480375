
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import eu from './assets/Eu.jpg'
import instagram from './assets/instagram.png';
import linkedin from './assets/linkedin.png';
import github from './assets/github.png';
import aws from './assets/image.png';
import aws_practitioner from './assets/aws1.png'
import SkillSection from './Components/SkillSection/SkillSection';
import letna from './assets/Letna_project.png'
import google from './assets/Google.png'

import skillsdata from './SkillsData';
import TypingEffect from './Components/TypingEffect/TypingEffect';




function App() {
  return (
    <>
      <Navbar />
      <section className='hero-section-introduction'>
        
        <div className='hero-title'>
          <h2>Hi There, <br/> I'm Ricardo Martins</h2>
          <p>I Am <span> <TypingEffect /> </span></p>
          <div className='hero-social'>
              <a href='https://www.instagram.com/ricardom4rtins/' target='blank'><img className='icon-social' src={instagram} alt='instagram'></img></a>
              <a href='https://www.linkedin.com/in/ricardo-martins07/' target='blank'><img className='icon-social' src={linkedin} alt='linkedin'></img></a>
              <a href='https://github.com/RicardoMartins07' target='blank'><img className='icon-social' src={github} alt='github'></img></a>
          </div>
        </div>

        <div className='hero-image'>
            <img src={eu} alt=''/>
        </div>

      </section>

      <section className='about-me'>
        <h2>About<span>Me</span></h2>
        <div class="content-wrapper">
            <div className='profile-img'>
              <img src={eu} alt=''/>
            </div>
            <div className='profile-info'>
                <h3>I'm Ricardo Martins</h3>
                <h4 class="job-title">Computer Engineer</h4>
                <p class="description">
                Passionate engineer with a fervour for software development. Solid experience in Web/Mobile development and SQL/PostgreSQL database administration.
                </p>

                <p className='description'>Vast deck of technical skills from C/C++ Programming, Python, NodeJS as well as
                frameworks such as React, Bootstrap, Angular.</p>

                <p className='description'>I stand out for my resilience, teamwork and motivation to thrive in a favorable
                environment.</p>
                <div class="contact-info">

                  <div className='info-esquerda'>
                    <p><span class="icon">👤</span> <strong>Age:</strong> 26</p>
                    <p><span class="icon">✉️</span> <strong>Email:</strong> <a href="mailto:rm45555@gmail.com" className='email'>rm45555@gmail.com</a></p>
                  </div>
                    
                  <div className='info-direita'>
                    <p><span class="icon">📞</span> <strong>Phone:</strong> +351 967394751</p>
                    <p><span class="icon">📍</span> <strong>Place:</strong> Viseu, Portugal</p>
                  </div>
                    
                </div>
                <a href="/Ricardo_martins_Resume.pdf" class="resume-btn"  download="Ricardo_martins_Resume.pdf">Resume</a>
            </div>
        </div>
      </section>

      <section className='skills'>
        <h2>Technologies and<span>Tools</span></h2>
        <div className='skill-wrapper'>
          <SkillSection skillsData={skillsdata} />

        </div>
      </section>

      <section className='projects'>
        <h2>My<span>Projects</span></h2>
        <div className='my-projects'> 

          <div className='project-card'>
            <a href='https://letna.pt/' target='blank'><img  src={letna} alt='projeto letna'/>
            <div className='project-info'>
              <h4 className='project-name'>Letna Group</h4>
              <p className='project-description'>A user-friendly website designed to showcase a diverse range of Letna Group products.</p>  
              <p className='project-description'>Users can easily browse and explore the catalog, making it simple to find detailed information about each product.</p>
            </div>
            </a>
          </div>

          
        </div>
            
    
      </section>

      <section className='awards'>
        <h2>Awards and <span>Certifications</span></h2>
        <div className='certifications'>
          <a href='https://www.credly.com/badges/f0e42a8e-fd2c-4881-a705-fd1c6a89b231/public_url' target='blank'><img src={aws_practitioner} alt='aws_practitioner'/></a>
          <a href='https://www.credly.com/badges/396acd44-0b9e-450f-9b1d-af515f2f8dcc/public_url' target='blank'><img src={aws} alt='aws_developer'/></a>
          <a href='https://www.credly.com/badges/66e62a90-eece-4150-959c-693bee6fcc57/public_url' target='blank'><img src={google} alt='Google Digital Leader'/></a>
        </div>
      </section>
      <footer className='footer'>
          <div className='footer-bottom'>
            <p className='copyright'>Copyright © 2024 All Rights Reserved by Ricardo Martins</p>
            <div className='social-footer'>
              <a href='https://www.instagram.com/ricardom4rtins/' target='blank'><img className='icon-social-footer' src={instagram} alt='instagram'></img></a>
              <a href='https://www.linkedin.com/in/ricardo-martins07/' target='blank'><img className='icon-social-footer' src={linkedin} alt='linkedin'></img></a>
              <a href='https://github.com/RicardoMartins07' target='blank'><img className='icon-social-footer' src={github} alt='github'></img></a>
            </div>
          </div>
      </footer>
    </>
  );
}

export default App;
