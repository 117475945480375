import React, { useState, useEffect } from "react";

  
  const TypingEffect = () => {
    const texts = ["A Front-End Developer", "A Back-End Developer", "A Computer Engineer" , "A Full-Stack Developer"];
    const [currentText, setCurrentText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopIndex, setLoopIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const typingSpeed = 130;
    const deletingSpeed = 85;
  
    useEffect(() => {
      const handleTyping = () => {
        const fullText = texts[loopIndex % texts.length];
  
        if (!isDeleting && charIndex < fullText.length) {
          setCurrentText(fullText.substring(0, charIndex + 1));
          setCharIndex(charIndex + 1);
        } else if (isDeleting && charIndex > 0) {
          setCurrentText(fullText.substring(0, charIndex - 1));
          setCharIndex(charIndex - 1);
        } else if (!isDeleting && charIndex === fullText.length) {
          setTimeout(() => setIsDeleting(true), 1000);
        } else if (isDeleting && charIndex === 0) {
          setIsDeleting(false);
          setLoopIndex(loopIndex + 1);
        }
      };
  
      const timeout = setTimeout(handleTyping, isDeleting ? deletingSpeed : typingSpeed);
  
      return () => clearTimeout(timeout);
    }, [charIndex, isDeleting, loopIndex]);
  
    return (
      
        <span className="cursor">{currentText} |</span>
      
    );
  };
  
  export default TypingEffect;