import React from 'react';
import './SkillCard.css'; // Importar CSS para estilização

const SkillCard = ({ skill }) => {
  return (
    <div className="skill-card">
      <img src={skill.img} alt={skill.alt}/>
        <p>{skill.name}</p>
    </div>
  );
};

export default SkillCard;